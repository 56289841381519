// Custom styles that don't fit into Bootstrap framework

// Font families
.font-cerebri-sans {
  font-family: "cerebri-sans-pro", sans-serif;
}

.font-cerebri-sans-bold {
  font-family: "cerebri-sans-pro-bold", sans-serif;
}

.font-cerebri-sans-xbold {
  font-family: "cerebri-sans-pro-xbold", sans-serif;
}

// Living elements
.living-element {
  position: absolute;
  z-index: 0;
  max-width: 100%;
  height: auto;
}

// Service and announcement cards
.service-card, .announcement-card {
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
}

// Calendar styles
.calendar-legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  
  &::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 0.5rem;
    border-radius: 50%;
  }
  
  &.blue::before {
    background-color: $geko-blue;
  }
  
  &.yellow::before {
    background-color: $geko-yellow;
  }
}

/* Generic Button Class */
.btn-geko {
  border-radius: 25px;
  padding: 0.25rem 0.5rem;
  font-family: "cerebri-sans-pro-bold";
  font-weight: 700;
  font-size: 1.25rem;
  transition: all 0.2s ease-in-out;
  border: none;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  
  &:hover, &:focus {
    box-shadow: -5px 5px 15px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(-2px);
  }
  
  /* Color variations */
  &.btn-primary {
    background-color: $geko-blue;
    color: white;
    
    &:hover, &:focus {
      background-color: darken($geko-blue, 5%);
    }
  }
  
  &.btn-secondary {
    background-color: $geko-yellow;
    color: $geko-black;
    
    &:hover, &:focus {
      background-color: darken($geko-yellow, 5%);
    }
  }
  
  &.btn-outline {
    background-color: transparent;
    border: 2px solid currentColor;
    
    &:hover, &:focus {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  
  /* Size variations */
  &.btn-sm {
    padding: 0.33rem 0.75rem;
    // font-size: 0.75rem;
    border-radius: 20px;
  }

  a {
    text-decoration: none;
  }
} 