// Import custom Bootstrap configuration
@import 'bootstrap';

// Import custom styles
@import 'mixins';
@import 'colors';
@import 'fonts';
@import 'fullcal';
@import 'calendar';
@import 'application';
@import 'announcements';
@import 'services_and_announcements';
@import 'footer';
// @import 'living_elements';
@import 'custom';

// Bootstrap overrides
// Add any Bootstrap variable overrides here
