html { background: white; }

/* Limit container width on larger screens while keeping full-width backgrounds */
@media (min-width: 1400px) {
  .container {
    max-width: 50% !important;
  }
}

#main-container {
  box-shadow: 0 0 10px rgba(black, 0.2);
  max-width: 100% !important;
}

// IMAGE EDGES
img { border-radius: 15px; }
img.no-border-radius { border-radius: 0px; }

body.has-navbar-fixed-top {
  overflow: hidden;
  padding-top: 0px;
}

body .content {
  width: 100%;
  max-width: 900px;

  section {
    background: rgb(250, 250, 250);
    border-bottom: 2px solid #dee2e5;
  }

  iframe { max-width: 100%; }

  .fa, .fab, .far { padding-right: 10px; }

  // LISTS
  li + li { margin-top: 25px; }

  ul {
    padding-bottom: 15px;
    margin-left: 0;
    list-style-type: none;

    li { 
      padding-left: 20px;
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 10px;
        height: 14px;
        background-image: url("/assets/img/Pfeil_Liste.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  // BUTTONS
  .button {
    @include hover-shadow;

    color: black !important;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

// GENERIC BLOCK STYLING
.hero {
  border: 0px;
  color: $black;
  position: relative;
  top: 40px;

  .hero-body {
    display: flex;
    justify-content: center;

    .content { position: relative; }
  }
}

.card {
  border-radius: 15px !important;
  border: 0px;
}

.contact-data {
  align-self: center;

  .box {
    padding: 0px !important;
    margin-bottom: 10px;
  }
}

// ABOUT US
.job-offers {
  a {
    text-decoration: underline;
    font-weight: 100;

    &:hover { color: $black; }
  }
}

// Custom job offers list with Bootstrap spacing
.job-offers-list {
  li + li {
    // This will be controlled by Bootstrap's mb-* classes instead
    margin-top: 0;
  }
  
  // Keep the arrow styling
  li {
    padding-left: 20px;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 14px;
      background-image: url("/assets/img/Pfeil_Liste.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.material-box {
  border: none;
  box-shadow: none;
  padding: 10px;

  .media-left { margin-right: 5px; }

  img { max-height: 40px; }

  .media-content {
    color: $black;

    h4 {
      word-break: keep-all;
      margin-bottom: 10px;
    }
  }
}

// CONTACT
#contact {
  a { font-weight: 100; }
  #contact-image { padding-right: 0px; }
  #contact-data { padding-left: 20px; }
  #map-image img {
    max-height: 400px;
    border: 1px solid $black;
  }
}
