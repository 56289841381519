// INDEX ITEMS
#services-and-announcements {
  .row .col { padding-bottom: 30px; }
}

// Service and announcement cards - Bootstrap compatible
.service-card, .announcement-card {
  transition: all 0.3s ease;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border: 0 !important;
  color: $black !important;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}

// Service card specific styles
.card.service-card {
  margin-bottom: 1rem;
  
  .card-body {
    display: flex;
    align-items: center;
    padding: 1rem;
    
    @include for-phone-only {
      height: 70px;
    }
    
    @include for-tablet-portrait-up {
      height: 80px;
    }
    
    @include for-tablet-landscape-up {
      height: 90px;
    }
    
    @include for-desktop-up {
      height: 100px;
    }
    
    @include for-big-desktop-up {
      height: 100px;
    }
    
    img {
      width: 70px;
      height: 70px;
      margin-right: 1rem;
      flex-shrink: 0;
      
      @include for-touch-devices {
        transform: scale(0.8);
      }
    }
    
    h3 {
      margin-bottom: 0 !important;
      font-weight: bold;
    }
  }
}

// SUBPAGES
.service-page, .announcement-page, .event-page {
  position: relative;

  // Resize Header image
  img.featured-image {
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

.service-page {
  position: relative;

  @include for-phone-only {
    background: linear-gradient(to bottom, $blue 10%, $white 10%, $white);
    top: $navbar-minimized;
    padding-bottom: 140px !important;
  }
  @include for-tablet-portrait-up {
    background: linear-gradient(to bottom, $blue 10%, $white 10%, $white);
    top: $navbar-minimized;
    padding-bottom: 140px !important;
  }
  @include for-tablet-landscape-up {
    background: linear-gradient(to bottom, $blue 12%, $white 12%, $white);
    top: $navbar-minimized;
    padding-bottom: 200px !important;
  }
  @include for-desktop-up {
    background: linear-gradient(to bottom, $blue 12%, $white 12%, $white);
    top: $navbar-height;
    padding-bottom: 300px !important;
  }
  @include for-big-desktop-up {
    background: linear-gradient(to bottom, $blue 12%, $white 12%, $white);
    top: $navbar-height;
    padding-bottom: 300px !important;
  }

  .service-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img.service-icon {
      position: relative;
      

      @include for-phone-only { transform: scale(1.0); margin-right: 5px; bottom: 5px; }
      @include for-tablet-portrait-up { transform: scale(1.0); margin-right: 5px; bottom: 5px; }
      @include for-tablet-landscape-up { transform: scale(1.2); margin-right: 10px; bottom: 5px; }
      @include for-desktop-up { transform: scale(1.2); margin-right: 15px; bottom: 7px; }
      @include for-big-desktop-up { transform: scale(1.2); margin-right: 15px; bottom: 7px; }
    }

    .title { margin: 0px; }
  }

  .service-card {
    &:hover {
      background-color: $blue;
      box-shadow: 0px 0px 0px 0px white;
    }
  }
}

.hero.event-page, .hero.announcement-page {
  position: relative;

  @include for-phone-only {
    background: linear-gradient(to bottom, $blue 15%, $white 15%, $white);
    top: $navbar-minimized;
    .hero-body { padding-bottom: 140px !important; }
  }
  @include for-tablet-portrait-up {
    background: linear-gradient(to bottom, $blue 25%, $white 25%, $white);
    top: $navbar-minimized;
    .hero-body { padding-bottom: 140px !important; }
  }
  @include for-tablet-landscape-up {
    background: linear-gradient(to bottom, $blue 18%, $white 18%, $white);
    top: $navbar-minimized;
    .hero-body { padding-bottom: 200px !important; }
  }
  @include for-desktop-up {
    background: linear-gradient(to bottom, $blue 25%, $white 12%, $white);
    top: $navbar-height;
    .hero-body { padding-bottom: 300px !important; }
  }
  @include for-big-desktop-up {
    background: linear-gradient(to bottom, $blue 25%, $white 12%, $white);
    top: $navbar-height;
    .hero-body { padding-bottom: 300px !important; }
  }
}

.hero.event-page {
  h2 { margin-bottom: 10px; }
  h3 { margin-bottom: 10px; }
  h4 { margin-bottom: 20px; }
}

// Service page
.service-page {
  position: relative;
  
  // Header gradient
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-color: $geko-blue;
    z-index: -1;
    
    @include media-breakpoint-up(md) {
      height: 250px;
    }
    
    @include media-breakpoint-up(lg) {
      height: 300px;
    }
  }
  
  .featured-image {
    width: 100%;
    height: auto;
    border-radius: $border-radius;
    margin-bottom: 1.5rem;
  }
  
  .service-title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    
    .service-icon {
      margin-right: 1rem;
      
      @include media-breakpoint-down(md) {
        width: 60px;
        height: 60px;
      }
      
      @include media-breakpoint-up(md) {
        width: 80px;
        height: 80px;
      }
    }
    
    h1 {
      margin: 0;
      color: $white;
    }
  }
}

// Event and announcement pages
.event-page, .announcement-page {
  position: relative;
  
  // Header gradient
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-color: $geko-blue;
    z-index: -1;
    
    @include media-breakpoint-up(md) {
      height: 250px;
    }
    
    @include media-breakpoint-up(lg) {
      height: 300px;
    }
  }
  
  .featured-image {
    width: 100%;
    height: auto;
    border-radius: $border-radius;
    margin-bottom: 1.5rem;
  }
  
  h2, h3, h4 {
    margin-bottom: 1rem;
  }
}
