@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-touch-devices {
  @media (max-width: 900px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1280px) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

@mixin hover-shadow {
  &:hover { box-shadow: -5px 5px 15px 0px rgba(0, 0, 0, 0.25); }
}

@mixin hover-greyscale {
  &:hover {
    -webkit-filter: grayscale(50);
    filter: grayscale(50);
    -webkit-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
  }
}
