// In pixels
$font-xxl: 60px;;
$font-xl: 42px;;
$font-ll: 33px;
$font-l: 30px;
$font-ml: 26px;
$font-m: 21px;
$font-s: 18px;
$font-xs: 15px;

// Font faces
@font-face {
  font-family: "cerebri-sans-pro";
  src: url("../fonts/CerebriSansPro-Regular.woff");
}

@font-face {
  font-family: "cerebri-sans-pro-bold";
  src: url("../fonts/CerebriSansPro-Bold.woff");
}

@font-face {
  font-family: "cerebri-sans-pro-xbold";
  src: url("../fonts/CerebriSansPro-ExtraBold.woff");
}


// Usage
body {
  font-family: 'cerebri-sans-pro', sans-serif;
  color: $black;

  .content button {
    font-family: "cerebri-sans-pro-xbold";
    font-size: $font-s;
    font-weight: bold;
    font-weight: 500 !important
  }
}

h1, h2, h3, h4, br, strong, .title {
  color: $black !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
  font-family: "cerebri-sans-pro-bold";
  font-weight: 500 !important;

  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

a {
  color: $black;
  font-weight: bold;
}

@include for-phone-only {
  body { font-size: $font-s; }

  #about, #contact, #welcome {
    ul li { text-align: start; }
  }

  h1 { font-size: $font-xl !important; } // Is h[i+1] on viewports! 
  h2 { font-size: $font-l !important; }
  h3 { font-size: $font-m !important; }
  h4 { font-size: $font-s !important; }
}

@include for-tablet-portrait-up {
  body { font-size: $font-s; }

  #about, #contact, #welcome {
    ul li { text-align: start; }
  }

  h1 { font-size: $font-xl !important; } // Is h[i+1] on viewports! 
  h2 { font-size: $font-l !important; }
  h3 { font-size: $font-m !important; }
  h4 { font-size: $font-s !important; }
}

@include for-tablet-landscape-up {
  body { font-size: $font-m; }

  h1 { font-size: $font-xxl !important; } 
  h2 { font-size: $font-xl !important; } 
  h3 { font-size: $font-l !important; } 
  h4 { font-size: $font-m !important; }
}

@include for-desktop-up {
  body { font-size: $font-m; }

  h1 { font-size: $font-xxl !important; } 
  h2 { font-size: $font-xl !important; } 
  h3 { font-size: $font-l !important; } 
  h4 { font-size: $font-m !important; }
}

@include for-big-desktop-up {
  body { font-size: $font-m; }

  h1 { font-size: $font-xxl !important; } 
  h2 { font-size: $font-xl !important; } 
  h3 { font-size: $font-l !important; } 
  h4 { font-size: $font-m !important; }
}

.index, figcaption { font-size: $font-xs !important; }

#footer { font-size: $font-s; }

.font-xs {
  font-size: $font-xs;
  font-weight: 100;
}

.box { color: $black; }

.announcement-card .card-content {
  :not(.title) { font-weight: 100; } 
}

.custom-button {
  height: 35px;
  border-radius: 15px;

  a {
    position: relative;
    top: 1px;
    font-family: "cerebri-sans-pro-bold" !important;
    font-size: $font-s !important; 
    text-decoration: none !important;
    font-weight: bold !important;

    &:hover { color: $black; }
  }
}
