@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/variables-dark';

// Custom colors
$geko-yellow: #fff15b;
$geko-blue: #58a9ff;
$geko-green: #71cf9a;
$geko-dark: #292f36;
$geko-white: #ffffff;
$geko-black: #000000;
$geko-red: #ff521c;

// Override Bootstrap theme colors
$theme-colors: (
  'primary': $geko-blue,
  'secondary': $secondary,
  'success': $geko-green,
  'info': $info,
  'warning': $geko-yellow,
  'danger': $danger,
  'light': $light,
  'dark': $geko-dark,
  'white': $geko-white,
  'black': $geko-black,
  'yellow': $geko-yellow,
  'blue': $geko-blue,
  'red': $geko-red
);

// Custom spacing
$spacers: (
  0: 0,
  1: 5px,
  2: 10px,
  3: 15px,
  4: 20px,
  5: 30px,
  6: 45px,
  7: 60px,
  8: 75px,
  9: 90px
);

// Custom font sizes
$font-sizes: (
  xs: 0.75rem,    // 12px
  s: 0.875rem,    // 14px
  m: 1rem,        // 16px
  ml: 1.125rem,   // 18px
  l: 1.25rem,     // 20px
  xl: 1.5rem,     // 24px
  xxl: 1.75rem,   // 28px
  xxxl: 2rem      // 32px
);

$enable-rfs: false;

// Navbar customization
$navbar-height: 120px;
$navbar-minimized: 90px;
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 1rem;
$navbar-brand-padding-y: 0;
$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: 1.25rem;
$navbar-toggler-border-radius: 15px;
$navbar-dark-toggler-border-color: transparent;
$navbar-light-toggler-border-color: transparent;

// Card customization
$card-border-width: 0;
$card-border-radius: 15px;
$card-cap-bg: transparent;

// Import Bootstrap components
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/utilities';

// Extend utilities
$utilities: map-merge(
  $utilities,
  (
    'font-size': map-merge(
      map-get($utilities, 'font-size'),
      (
        values: $font-sizes,
        responsive: true
      )
    )
  )
);

// Import Bootstrap components
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules/bootstrap/scss/accordion';
@import '../node_modules/bootstrap/scss/breadcrumb';
@import '../node_modules/bootstrap/scss/pagination';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/alert';
@import '../node_modules/bootstrap/scss/progress';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/close';
@import '../node_modules/bootstrap/scss/toasts';
@import '../node_modules/bootstrap/scss/modal';
@import '../node_modules/bootstrap/scss/tooltip';
@import '../node_modules/bootstrap/scss/popover';
@import '../node_modules/bootstrap/scss/carousel';
@import '../node_modules/bootstrap/scss/spinners';
@import '../node_modules/bootstrap/scss/offcanvas';
@import '../node_modules/bootstrap/scss/placeholders';
@import '../node_modules/bootstrap/scss/helpers';
@import '../node_modules/bootstrap/scss/utilities/api'; 