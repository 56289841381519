#loading {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

#calendar {
  // max-width: 1100px;
  margin: 0 auto;
  font-family: "cerebri-sans-pro";

  .fc-header-toolbar {
    h2 {
      font-size: 1rem;
    }
  }

  .fc-list {
    border-radius: 15px;
  }

  .fc-list-day-cushion {
    background-color: $white;
    border-radius: 14px 14px 14px 14px;
  }

  tbody th {
    border-radius: 15px 15px 0px 0px;
  }

  tbody tr {
    border: hidden;

    td.fc-list-event-time{ border-radius: 14px 0px 0px 14px; }
    td.fc-list-event-title { border-radius: 0px 14px 14px 0px; }
  }

  .fc-list-event:hover td {
    cursor: pointer;
    background-color: $red-translucent !important;
  }

}

#calendar-legend {
  a { text-decoration: underline; }

  ul {
    margin-left: 35px;
    list-style: none; /* Remove default bullets */


    li::before {
      content: "\2022";
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      font-size: 30px;

      &.background-color-yellow { color: $yellow; }
      &.background-color-blue { color: $blue; }
    }

    li.calendar-legend-item-ar::before {
      margin-left: 0em;
    }

    li span {
      padding-left: 5px;
      font-size: 18px;
    }
  }
}

// .calendar-modal {
//   .modal-content { direction: ltr; }
// }
