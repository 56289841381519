$white: #fff;
$black: #000;
$yellow: #fff15b;
$lilac: #dbd0ff;
$green: #71cf9a;
$grey-green: #b8e7cc;
$turqoise: #80ddd2;
$dark-grey: #2e2e2e;
$light-grey: #ececec;
$blue: #58a9ff;
$red: #ff521c;
$red-translucent: lighten(rgba(255, 82, 28, 0.5), 25);

$text-dark: #34495e;
