#footer {
  #logo-kollektiv {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left !important;
    margin-left: -27px;

    @include for-phone-only { margin-left: -0px; }

    img { height: 80px; }
  }

  a {
    color: $black !important;
    text-decoration: underline;
    font-weight: 100;
  }

  p { margin-bottom: 25px; }

  .contact-data {
    align-self: normal;

    a { text-decoration: none; }
  }
}

#supporters .supporter-columns {
  padding: 0px !important; 

  .column {
    padding-bottom: 60px;
    display: flex;
    align-items: center;

    .feature-text {
      position: relative;
      bottom: 50%;
      margin-right: 30px;
    }
  }
  
  // Apply styles to all images in supporters section
  img {
    &:hover {
      -webkit-filter: grayscale(50);
      filter: grayscale(50);
      -webkit-transition: .15s ease-in-out;
      transition: .15s ease-in-out;
    }
  }
}

// Global class for removing border radius
.no-border-radius {
  border-radius: 0 !important;
}
